import counterUp from 'counterup2'

$('html').removeClass('no_js');
$('html').addClass('js');

var AppSettings = {
  DEBUGMODE: true //change to turn on/off console.log statements
};

/**
 * Simple debug setup for logging to the console
 * @type {Object}
 */
var Debug = {
    log: function(string, variable) {
      if (AppSettings.DEBUGMODE) {
        try {
          console.log(string, variable);
        } catch (e) {}
      }
    },
    warn: function(string, variable) {
      if (AppSettings.DEBUGMODE) {
        try {
          console.warn(string, variable);
        } catch (e) {}
      }
    }
};

var Graph = {
  init: function() {
    
  }
};


var SmoothScroll = {
  init: function() {
    
    var ctrl = new ScrollMagic.Controller()

    $("section").each(function(i) {
      var outer = $(this).find(".container-fluid")
      
      new ScrollMagic.Scene({
        triggerElement: this,
        triggerHook: 0.6
      })
        .duration(outer.height())
        .reverse(true)
        // .addIndicators({
        //   colorTrigger: "white",
        //   colorStart: "white",
        //   colorEnd: "white",
        //   indent: 40
        // })
        .on('enter', function(e){
          var sectionElement = outer.parent('section')
          let outerData = outer.data()
          
          // this that need to happen every enter
          SmoothScroll.pageBg(outerData.screenbg)
          SmoothScroll.bodyClass(outerData.bodyclass)
          SmoothScroll.staticFooter(outerData.showfooter)

          // animations that must only run once
          if (!outer.hasClass('active')) {
            SmoothScroll.graphs(outer)
          }

          // add active class
          outer.addClass('active')

          $('section').removeClass('active')
          outer.closest('section').addClass('active')
        })
        .addTo(ctrl)
    });
  },
  staticFooter: function(newClass) {
    if (newClass == "show") {
      $(".staticFooter__container").addClass('openStaticFooter')
    } else if (newClass == "hide") {
      $(".staticFooter__container").removeClass('openStaticFooter')
    }
  },
  pageBg: function(newClass) {
    $("#screenBackground").removeClass();
    if (newClass) {
      $("#screenBackground").addClass('backgroundColor__' + newClass)
    }
  },
  bodyClass: function(newClass) {
    $('body').removeClass()
    if (newClass) {
      $('body').addClass(newClass)
    }
  },
  graphs: function(outer) {
    var graphs = outer.find(".percentBar__container")
    if (graphs.length) {
      let percentBarWrapperArray = graphs.find('.percentBar__wrapper')

      $(percentBarWrapperArray).each(function(index, item) {
        $(this).find('.percentBar .percentBar__inner').css({ width: 0 })

        let itemData = $(this).data()
        let graphWidth = 0
        let graphDelay = 0

        if (itemData.graphvalue) {
          graphWidth = itemData.graphvalue
        }
        if (itemData.graphdelay) {
          graphDelay = itemData.graphdelay
        }
        $(this).find('.percentBar .percentBar__inner').delay(graphDelay).animate({ width: graphWidth + '%' }, 1000);
        
        let counterEl = $(this).find('.graphvalue')[0]
        if (counterEl) {
          counterUp(counterEl, {
            duration: 1000,
            delay: 20
          });
        }
      });
    }
  }
};


var ProgressBar = {
  init: function() {
    var controller = new ScrollMagic.Controller()

    var body = document.body;
    var html = document.documentElement;
    var FullPageHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
    let lastSectionHeight = $("body section").last().height()

    var scrollBar = $(".indicator");
    
    let fullScroll = new ScrollMagic.Scene({
      triggerElement: 'body',
      triggerHook: 0
    })
    .duration(FullPageHeight - lastSectionHeight)
    .on("progress", function (event) {
      let setWidth = (Math.floor(event.progress * 100000) / 1000) + "%"
      scrollBar.height(setWidth)
    })
    .addTo(controller);
  },
  manualCalc: function() {
    // var body = document.body;
    // var html = document.documentElement;

    // var height = 0;
    // var h = 0;

    // var initiateHeights = function () {
    //   height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
    //   h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    // }

    // initiateHeights();

    // var resize = function (e) {
    //   var scrolled = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
    //   height > 0 ? e[0].style.width = scrolled/(height-h) * 100 + "%" : e.style.width = 0 + "%";
    // }

    // document.onscroll = function () {
    //   resize(document.getElementsByClassName("indicator"));
    // };

    // window.onresize = function () {
    //   initiateHeights();
    // }
  }
};

var Main = {
  run: function() {
    Debug.log('Custom Scripts Running');
    SmoothScroll.init();
    ProgressBar.init();
    // Graph.init();
    // waypoint.init();
  }
};

$(document).ready(Main.run);
